import React, { useState } from 'react';
import { withApollo } from 'react-apollo';
import { useGlobal } from 'reactn';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import { Select, Checkbox, InputNumber } from 'antd';
import Button from '../Button';
import Form from '../Form';
import Input from '../Input';
import { UPDATE_DELIVERABLE, ADD_DELIVERABLE } from './mutation';
import { showError, showSuccess } from '../../notifications';
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const REMOVE_PROJECT_DELIVERABLE = gql`
  mutation removeDeliverable($deliverableId: ID!) {
    removeDeliverable(deliverableId: $deliverableId)
  }
`;

export const updateDeliverable = ({
  values,
  setSubmitting,
  deliverable,
  isMilestone,
  client,
  refetchQueries,
  onComplete,
  usePercentage,
}) => {
  if (deliverable._id) {
    if (isMilestone && !values.budget && !values.budgetPercent) {
      client
        .mutate({
          mutation: REMOVE_PROJECT_DELIVERABLE,
          variables: {
            deliverableId: deliverable._id,
          },
          awaitRefetchQueries: true,
          refetchQueries,
        })
        .then(() => {
          setSubmitting(false);
          onComplete();
          // showSuccess({ message: `Successfully updated deliverable.` });
        })
        .catch(err => {
          // console.log('Error Message: ', err.message);
          showError({ message: err.message });
        });
    } else {
      return client
        .mutate({
          mutation: UPDATE_DELIVERABLE,
          variables: {
            id: deliverable._id,
            ...values,
            disabled: values.disabled ? values.disabled : false,
            rate: values.rate ? values.rate : null,
            budget: !usePercentage && values.budget ? Number(values.budget) : null,
            budgetPercent:
              usePercentage && values.budgetPercent ? Number(values.budgetPercent) : null,
          },
          awaitRefetchQueries: true,
          refetchQueries,
        })
        .then(() => {
          setSubmitting(false);
          onComplete();
          showSuccess({ message: `Successfully updated deliverable.` });
        })
        .catch(err => {
          // console.log('Error Message: ', err.message);
          showError({ message: err.message });
        });
    }
  } else {
    return client
      .mutate({
        mutation: ADD_DELIVERABLE,
        variables: {
          projectId: deliverable.projectId,
          ...values,
          rate: values.rate ? values.rate : null,
          budget: !usePercentage && values.budget ? Number(values.budget) : null,
          budgetPercent:
            usePercentage && values.budgetPercent ? Number(values.budgetPercent) : null,
        },
        awaitRefetchQueries: true,
        refetchQueries,
      })
      .then(() => {
        setSubmitting(false);
        onComplete();
        showSuccess({ message: `Successfully updated deliverable.` });
      })
      .catch(err => {
        // console.log('Error Message: ', err.message);
        showError({ message: err.message });
      });
  }
};

const UpdateDeliverableForm = ({
  onComplete,
  client,
  deliverable = {},
  refetchQueries = [],
  milestoneNames,
  isMilestone,
  milestoneBudget,
  disabled,
}) => {
  const [user] = useGlobal('user');
  const [createNew, setCreateNew] = useState(false);
  const [usePercentage, setUsePercentage] = useState(deliverable.budgetPercent > 0);
  const financeGroupInc = user.groups.includes('finance');

  return (
    <Formik
      initialValues={{
        ...deliverable,
        deliverableName: isMilestone ? deliverable.milestoneName : deliverable.deliverableName,
      }}
      validate={values => {
        const errors = {};
        // console.log({ values });
        // if (!values.password) {
        //   !setEmailCheck.isSpruceEmail
        //     ? (errors.password = 'Password is definitely required')
        //     : (errors.password = "I can't let you do this");
        // }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await updateDeliverable({
          values,
          setSubmitting,
          deliverable,
          isMilestone,
          client,
          refetchQueries,
          onComplete,
          usePercentage,
          disabled,
        });
      }}
    >
      {({ values, setFieldValue, handleChange, handleSubmit, isSubmitting }) => {
        const {
          deliverableName,
          milestoneName,
          budget,
          budgetPercent,
          rate,
          accountingCodeSuffix,
          accountingCode,
        } = values;

        return (
          <Form onSubmit={handleSubmit} {...formItemLayout}>
            {!isMilestone && (
              <>
                <Form.Item label="Category" hasFeedback>
                  <Select
                    disabled={createNew}
                    name="cagegory"
                    showSearch
                    defaultValue={milestoneName}
                    placeholder="Select category"
                    optionFilterProp="children"
                    onChange={category => {
                      setFieldValue('milestoneName', category);
                    }}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {milestoneNames &&
                      milestoneNames.map(milestoneName => {
                        return (
                          <Option value={milestoneName} key={milestoneName}>
                            {milestoneName}
                          </Option>
                        );
                      })}
                  </Select>
                  <Checkbox checked={createNew} onChange={e => setCreateNew(e.target.checked)}>
                    Create New
                  </Checkbox>
                </Form.Item>
                {createNew && (
                  <Form.Item label="New Category" hasFeedback help="Specify new category">
                    <Input name="milestoneName" onChange={handleChange} value={milestoneName} />
                  </Form.Item>
                )}
                <Form.Item label="Deliverable" hasFeedback>
                  <Input name="deliverableName" onChange={handleChange} value={deliverableName} />
                </Form.Item>
                <Form.Item
                  label="Rate"
                  hasFeedback
                  help={'Specify a rate to override the role rate for this task.'}
                >
                  <InputNumber
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={rate => setFieldValue('rate', rate)}
                    name="rate"
                    value={rate}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              label="Budget"
              hasFeedback
              help={milestoneBudget && 'Budget has been set at the category level.'}
            >
              <Input
                onChange={e => {
                  if (usePercentage) {
                    setFieldValue('budgetPercent', e.target.value);
                    setFieldValue('budget', null);
                  } else {
                    setFieldValue('budgetPercent', null);
                    setFieldValue('budget', e.target.value);
                  }
                }}
                name="budget"
                addonAfter={
                  <Select
                    defaultValue={usePercentage ? '%' : 'USD'}
                    onChange={value => {
                      if (value === 'percent') {
                        setUsePercentage(true);
                      } else {
                        setUsePercentage(false);
                      }
                    }}
                    style={{ width: 60 }}
                  >
                    <Option value="percent">%</Option>
                    <Option value="USD">$</Option>
                  </Select>
                }
                disabled={!!milestoneBudget}
                value={usePercentage ? budgetPercent : budget}
              />
            </Form.Item>
            {!isMilestone && (
              <Form.Item label="Disabled" hasFeedback>
                <Checkbox
                  defaultChecked={disabled}
                  onChange={e => {
                    setFieldValue('disabled', e.target.checked);
                  }}
                >
                  Disable this deliverable?
                </Checkbox>
              </Form.Item>
            )}
            {financeGroupInc && (
              <>
                <Form.Item label="BU Code" hasFeedback>
                  <Input name="accountingCode" onChange={handleChange} value={accountingCode} />
                </Form.Item>
                <Form.Item label="IS Code" hasFeedback>
                  <Input
                    name="accountingCodeSuffix"
                    onChange={handleChange}
                    value={accountingCodeSuffix}
                  />
                </Form.Item>
              </>
            )}
            <div className="flex justify-end">
              <Button loading={isSubmitting} type="primary" htmlType="submit">
                Update Deliverable
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default withApollo(UpdateDeliverableForm);
