import React from 'react';
import moment from 'moment';
import Tag from '../../common/components/Tag';
import Table from '../../common/components/Table';
import Tooltip from '../../common/components/Tooltip';
import TextLoader from '../../common/components/TextLoader';
import TimesheetActionsContainer from './TimesheetActionsContainer';
import Profile from '../../common/components/Profile';
import { getColorByStatus } from '../../common';
import TimesheetApprovers from './TimesheetApprovers';
import { GET_TIMESHEETS_FOR_APPROVAL } from '../queries';

class TimesheetApprovalList extends React.Component {
  render() {
    const { timesheets, loading, user, projectId } = this.props;

    const columns = [
      {
        title: 'Resource',
        key: 'resourceName',
        width: 200,
        render: ({ user, resource }) => {
          const { firstName, lastName, imageUrl, title } = user;
          const role = resource?.role;
          const email = resource?.email;
          return (
            <Profile
              imageUrl={imageUrl}
              title={role || title}
              firstName={firstName}
              lastName={lastName}
              email={email}
            />
          );
        },
      },
      {
        title: 'Week / Hours',
        key: 'weekOf',
        width: 130,
        render: ({ endDate, totalHours }) => {
          const style = {
            color: 'black',
            fontWeight: 'normal',
            display: 'flex',
          };
          if (totalHours >= 50) {
            style.color = 'red';
            style.fontWeight = 'bold';
          }
          return (
            <div className="flex flex-col">
              <div>{moment(endDate).format('L')}</div>
              <div style={style}>
                {totalHours + ' hours'}
                {totalHours >= 50 ? (
                  <Tooltip placement="top" title={'This timesheet has over 50 hours'}>
                    <img
                      alt=""
                      style={{
                        height: '20px',
                        width: '20px',
                        paddingLeft: '5px',
                        paddingTop: '5px',
                      }}
                      src="/disclaimer-icon.jpg"
                    />
                  </Tooltip>
                ) : (
                  ''
                )}
              </div>
            </div>
          );
        },
      },
      {
        title: 'Approvals',
        dataIndex: 'approvalsRequired',
        key: 'approvalsRequired',
        width: 100,
        render: approvalsRequired => {
          return <TimesheetApprovers approvalsRequired={approvalsRequired} />;
        },
      },
      {
        title: 'Overall Status',
        dataIndex: 'status',
        key: 'status',
        width: 150,
        render: status => (
          <Tooltip title="Overall timesheet status" trigger="hover">
            <Tag color={getColorByStatus(status)}>{status}</Tag>
          </Tooltip>
        ),
      },
      {
        title: 'My Approval',
        dataIndex: 'approvalsRequired',
        key: 'myStatus',
        width: 150,
        render: approvalsRequired => {
          // make sure user is present in props
          if (!user) {
            return null;
          }
          const userEmail = user.emails[0].address;
          const approval = approvalsRequired.find(approval =>
            approval.canApprove.includes(userEmail),
          );
          const status =
            (approval &&
              approval.status &&
              approval.approvedBy?.toLowerCase() === userEmail.toLowerCase()) ||
            'Approval Pending';

          return (
            <Tooltip title="Status of your approval" trigger="hover">
              <Tag color={getColorByStatus(status)}>{status}</Tag>
            </Tooltip>
          );
        },
      },
      {
        title: 'Actions',
        key: 'actions',
        width: 150,
        render: timesheet => (
          <TimesheetActionsContainer
            timesheet={timesheet}
            projectId={projectId}
            refetchQueries={[
              {
                query: GET_TIMESHEETS_FOR_APPROVAL,
                variables: { projectId },
              },
            ]}
          />
        ),
      },
    ];
    if (loading) {
      return <TextLoader text="Loading timesheets" />;
    }
    return (
      <Table
        columns={columns}
        pagination={false}
        locale={{
          emptyText: 'You currently do not have any timesheets to approve.',
        }}
        dataSource={
          // make sure timesheets exist and arrived to browser
          timesheets &&
          timesheets
            // add key property to timesheet objects. Its used by <Table />
            .map(t => ({ ...t, key: t._id }))
            // sort by endDate
            .sort((a, b) => {
              const diff = moment(b.endDate).diff(moment(a.endDate), 'days');
              return diff;
            })
        }
      />
    );
  }
}

export default TimesheetApprovalList;
