import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import classNames from 'classnames';
import { Empty } from 'antd';
import TimesheetGridRow from './TimesheetGridRow';
import TimesheetGridRowAddNew from './TimesheetGridRowAddNew';
import Tag from '../../common/components/Tag';
import Tooltip from '../../common/components/Tooltip';
import { GET_AVAILABLE_DELIVERABLES } from '../queries';
import { Query } from 'react-apollo';
import ProjectBudgetIndicator from './ProjectBudgetIndicator';
import ProjectMilestoneBudgetIndicator from './ProjectMilestoneBudgetIndicator';
import { getWeekDays } from '../../common';

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
`;
const Header = styled.thead``;
const HeaderRow = styled.tr``;
const HeaderCol = styled.th`
  padding: 1em;
  border-bottom: 1px solid #c5c5c5;
  ${props => props.showTopBorder && 'border-top: 1px dashed #c5c5c5;'};
  ${props => props.showRightBorder && 'border-right: 1px dashed #c5c5c5;'};
  ${props => props.showLeftBorder && 'border-left: 1px dashed #c5c5c5;'};
  text-align: ${props => (props.align ? props.align : 'initial')};
  width: ${props => props.width};
  &:first-child {
    padding-left: 0;
  }
`;

const Day = styled.div`
  display: flex;
  justify-content: center;
`;

const ProjectNameRow = styled.tr`
  border-top: 1px solid #f1f1f1;
  &:first-child {
    border-top: none;
  }
  > td {
    padding: 1rem 0 0.5rem 0;
    font-weight: bold;
  }
`;

// use lowercase 3 char week day
const sumTotalsForWeekDay = (weekDay, lineItems) =>
  lineItems.reduce((acc, cur) => {
    const weekDayTask = cur[`${weekDay}Task`];
    if (cur.deliverable.ignoreHoursInTotal) {
      return acc;
    }

    if (weekDayTask) {
      return acc + weekDayTask.hours;
    }

    return acc;
  }, 0);

export const constructDailyTotals = lineItems => ({
  mon: sumTotalsForWeekDay('mon', lineItems),
  tue: sumTotalsForWeekDay('tue', lineItems),
  wed: sumTotalsForWeekDay('wed', lineItems),
  thu: sumTotalsForWeekDay('thu', lineItems),
  fri: sumTotalsForWeekDay('fri', lineItems),
  sat: sumTotalsForWeekDay('sat', lineItems),
  sun: sumTotalsForWeekDay('sun', lineItems),
});

const TimesheetGrid = ({ isLocked, lineItems, timesheetId, endDate, user }) => {
  const projects = lineItems
    .reduce((acc, cur) => {
      if (acc.find(p => p._id === cur.project._id)) {
        return acc;
      }
      return [
        ...acc,
        {
          ...cur.project,
        },
      ];
    }, [])
    .sort((a, b) => a.name.localeCompare(b.name));

  const projectsWithTasks = projects.map(p => {
    return {
      ...p,
      lineItems: lineItems
        // lineItemIndex is used to determine which item in the timesheet lineItem array to remove.
        // since we are splitting lineItems into project groups, we have to carry over original index in the original lineItems array
        .map((i, index) => ({ ...i, lineItemIndex: index }))
        .filter(i => i.project._id === p._id),
    };
  });

  // if (lineItems.length === 0) {
  //   return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  // }

  const selectedDeliverableIds = lineItems.map(li => li.deliverable._id);
  const weekDays = getWeekDays(endDate);

  return (
    <Table>
      <Header>
        <HeaderRow>
          <HeaderCol width="initial">Tasks</HeaderCol>
          <HeaderCol width="50px" />
          {weekDays.map((day, index) => {
            let observedHoliday = null;
            const currentDate = moment(endDate).subtract(6 - index, 'days');
            const displayedDay = currentDate.format('ddd D');
            const holiday = null;
            const isWeekend = currentDate.day() === 6 || currentDate.day() === 0;

            // const holiday = currentDate.isHoliday();
            // if (day === 'Fri') {
            //   observedHoliday = currentDate
            //     .clone()
            //     .add(1, 'days')
            //     .isHoliday();
            // }
            // if (day === 'Mon') {
            //   observedHoliday = currentDate
            //     .clone()
            //     .subtract(1, 'days')
            //     .isHoliday();
            // }

            // TODO handle bug with moment holdiday. Remove in future.
            if (currentDate.format('YYYYMMDD') === '20191122') {
              return (
                <HeaderCol key={day} width="80px" align="center">
                  <Day>{displayedDay}</Day>
                </HeaderCol>
              );
            }
            if (currentDate.format('YYYYMMDD') === '20191129') {
              return (
                <HeaderCol key={day} width="80px" align="center">
                  <Day>
                    <Tooltip title="Day after Thanksgiving">
                      <Tag color="blue">{displayedDay}</Tag>
                    </Tooltip>
                  </Day>
                </HeaderCol>
              );
            }

            return (
              <HeaderCol
                key={day}
                width="80px"
                align="center"
                showTopBorder={isWeekend}
                showRightBorder={currentDate.day() === 0}
                showLeftBorder={currentDate.day() === 6}
                className={classNames('', { 'bg-gray-100': isWeekend })}
              >
                <Day className={classNames({
                  'font-bold': !isWeekend,
                })}>
                  {observedHoliday || holiday ? (
                    <Tooltip title={observedHoliday ? `${observedHoliday} (observed)` : holiday}>
                      <Tag color={observedHoliday ? 'purple' : 'blue'}>{displayedDay}</Tag>
                    </Tooltip>
                  ) : (
                    displayedDay
                  )}
                </Day>
              </HeaderCol>
            );
          })}
          {!isLocked && <HeaderCol width="80px" align="center" />}
        </HeaderRow>
      </Header>
      <tbody>
        {lineItems?.length > 0 ? (
          projectsWithTasks.map(p => {
            const milestones = p.lineItems.reduce((acc, cur) => {
              if (acc.find(item => item.name === cur.deliverable.milestoneName)) {
                return acc;
              }
              return [
                ...acc,
                { deliverableId: cur.deliverable._id, name: cur.deliverable.milestoneName },
              ];
            }, []);
            const projectsLineItems = p.lineItems.filter(li => li.project._id === p._id);
            return (
              <React.Fragment key={p._id}>
                <ProjectNameRow>
                  <td colSpan={9}>
                    <div className="flex">
                      <div className="font-bold">{p.name}</div>
                      {!isLocked && (
                        <ProjectBudgetIndicator projectId={p._id} lineItems={projectsLineItems} />
                      )}
                    </div>
                  </td>
                </ProjectNameRow>

                {milestones
                  .sort((a, b) => {
                    const nameA = a.name;
                    const nameB = b.name;
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                  })
                  .map(milestone => {
                    const lineItems = p?.lineItems.filter(li => {
                      return li.deliverable.milestoneName === milestone.name;
                    });
                    return (
                      <React.Fragment key={milestone.deliverableId}>
                        <tr>
                          <td colSpan={9}>
                            <div className="flex">
                              <div className="uppercase font-semibold">{milestone.name}</div>
                              {!isLocked && (
                                <ProjectMilestoneBudgetIndicator
                                  projectId={p._id}
                                  milestoneName={milestone.name}
                                  deliverableId={milestone.deliverableId}
                                  milestoneLineItems={lineItems}
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                        {lineItems.map((row, index) => (
                          <TimesheetGridRow
                            isLocked={isLocked || row.status === 'Approved'}
                            endDate={endDate}
                            key={`${p._id}${row.deliverable._id}`}
                            timesheetId={timesheetId}
                            lineItem={row}
                            lineItemIndex={row.lineItemIndex}
                            user={user}
                          />
                        ))}
                      </React.Fragment>
                    );
                  })}
              </React.Fragment>
            );
          })
        ) : (
          <tr>
            <td colSpan={9}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Your tasks for this timesheet will appear here."
              />
            </td>
          </tr>
        )}
        <Query query={GET_AVAILABLE_DELIVERABLES} variables={{ user: user, endDate }}>
          {({ loading, error, data }) => {
            if (error) throw new Error(error);
            return (
              <TimesheetGridRowAddNew
                selectedDeliverableIds={selectedDeliverableIds}
                loading={loading}
                isLocked={isLocked}
                endDate={endDate}
                timesheetId={timesheetId}
                dailyTotals={constructDailyTotals(lineItems)}
                user={user}
                data={data}
                lineItems={lineItems?.length || 0}
                onSubmit={() => {}}
                onSubmitEnd={() => {}}
              />
            );
          }}
        </Query>
      </tbody>
    </Table>
  );
};

export default TimesheetGrid;
