import React, { useState } from 'react';
import moment from 'moment';
import { Button, Input, Icon } from 'antd';
import Tag from '../../common/components/Tag';
import Table from '../../common/components/Table';
import Tooltip from '../../common/components/Tooltip';
import TextLoader from '../../common/components/TextLoader';
import TimesheetActionsContainer from './TimesheetActionsContainer';
import { getColorByStatus } from '../../common';
import Profile from '../../common/components/Profile';
import TimesheetApprovers from './TimesheetApprovers';

const createColumns = ({ searchInput, onSearch, resetSearch, user }) => [
  {
    title: 'Resource',
    key: 'resourceName',
    width: '30%',
    render: ({ user, resource }) => {
      const { firstName, lastName, imageUrl, title } = user;
      const role = resource?.role;
      const email = resource?.email;
      return (
        <Profile
          imageUrl={imageUrl}
          title={role || title}
          firstName={firstName}
          lastName={lastName}
          email={email}
        />
      );
    },
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search Name`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => onSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => onSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => resetSearch(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, { user, role, email }) => {
      const { firstName, lastName, title } = user;
      const searchString = firstName + lastName + email + (role || title);
      return searchString.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
  },
  {
    title: 'Hours',
    dataIndex: 'totalHours',
    key: 'totalHours',
  },
  {
    title: 'Approvals',
    dataIndex: 'approvalsRequired',
    key: 'approvalsRequired',
    width: 100,
    render: approvalsRequired => {
      return <TimesheetApprovers approvalsRequired={approvalsRequired} />;
    },
  },
  {
    title: 'Week',
    dataIndex: 'endDate',
    key: 'weekOf',
    render: endDate => moment(endDate).format('L'),
  },
  {
    title: 'Overall Status',
    dataIndex: 'status',
    key: 'status',
    render: status => (
      <Tooltip title="Overall timesheet status" trigger="hover">
        <Tag color={getColorByStatus(status)}>{status}</Tag>
      </Tooltip>
    ),
  },
  {
    title: 'My Approval',
    dataIndex: 'approvalsRequired',
    key: 'myStatus',
    render: approvalsRequired => {
      // make sure user is present in props
      if (!user) {
        return null;
      }
      const userEmail = user.emails[0].address;
      const myApproval = approvalsRequired.find(approval =>
        approval.canApprove.includes(userEmail),
      );
      const status = (myApproval && myApproval.status) || 'Approval Pending';

      return (
        <Tooltip title="Status of your approval" trigger="hover">
          <Tag color={getColorByStatus(status)}>{status}</Tag>
        </Tooltip>
      );
    },
  },
  {
    title: 'Actions',
    key: 'actions',
    width: 200,
    render: timesheet => <TimesheetActionsContainer timesheet={timesheet} showCancelButton />,
  },
];

const TimesheetsApprovedList = ({ timesheets, loading, user, isPaginated = true }) => {
  const [searchInput, setSearchInput] = useState(null);

  if (loading) {
    return <TextLoader text="Loading timesheets" />;
  }
  return (
    <Table
      columns={createColumns({
        user,
        searchInput,
        resetSearch: clearFilters => {
          clearFilters();
          setSearchInput(null);
        },
        onSearch: (selectedKeys, confirm) => {
          confirm();
          setSearchInput(selectedKeys[0]);
        },
      })}
      pagination={isPaginated}
      locale={{
        emptyText: 'You do not have any approved timesheets for this time period',
      }}
      dataSource={
        // make sure timesheets exist and arrived to browser
        timesheets &&
        timesheets
          // add key property to timesheet objects. Its used by <Table />
          .map(t => ({ ...t, key: t._id }))
          // sort by endDate
          .sort((a, b) => {
            const diff = moment(b.endDate).diff(moment(a.endDate), 'days');
            return diff;
          })
      }
    />
  );
};

export default TimesheetsApprovedList;
